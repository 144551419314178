// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          primary: "#004C88",
          primary2: "#8D1913",
          secondary: "#a7a6ba",
          success: "#5cb85c",
          info: "#48cae4",
          warning: "#004C88",
          error: "#ef5350",
          dark: "#212121",
          light: "#F5F5F5",
          background: "#F5F5F5",
          text: "#004C88",
        },
      },
    },
  },
});

export default vuetify;
