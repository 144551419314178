import homeServices from "@/services/home"

const state = {
  loading: false,
  news: []
}

const getters = {

}

const mutations = {
  getNewsStart(state) {
    state.loading = true
  },
  getNewsSuccess(state, news) {
    state.loading = false
    state.news = news
  }
}

const actions = {
  getNews({ commit }) {
    return new Promise((resolve) => {
      commit("getNewsStart")
      homeServices.getNews().then(response => {
        commit("getNewsSuccess", response.data)
        resolve(response)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}