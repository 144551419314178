import Vue from "vue";
import "@/assets/style.css";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@mdi/font/css/materialdesignicons.css";
import "remixicon/fonts/remixicon.css";
import AOS from "aos";
import "aos/dist/aos.css";
import MasonryWall from '@yeger/vue-masonry-wall'
import i18n from "./locales";

const app = createApp(App);

app.use(store);
app.use(router);
app.use(vuetify);
app.use(i18n)
app.component('MasonryWall', MasonryWall)
AOS.init();

app.mount("#app");
