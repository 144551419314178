import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import('@/layout/default.vue'),
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('@/views/HomeView.vue')
      },
      {
        path: '/welcome-speech',
        name: 'welcome-speech',
        component: () => import('@/views/WelcomeSpeechView.vue')
      },
      {
        path: '/kampusies',
        name: 'kampusies',
        component: () => import('@/views/KampusiesView.vue')
      },
      {
        path: '/purpose-mission',
        name: 'purpose-mission',
        component: () => import('@/views/PurposeMissionView.vue')
      },
      {
        path: '/symbols',
        name: 'symbols',
        component: () => import('@/views/SymbolsView.vue')
      },
      {
        path: '/contact-us',
        name: 'contact-us',
        component: () => import('@/views/ContactUsView.vue')
      },
      {
        path: '/faculties',
        name: 'faculties',
        component: () => import('@/views/FacultiesView.vue')
      },
      {
        path: '/exam-samples',
        name: 'exam-samples',
        component: () => import('@/views/ExamSamplesView.vue')
      },
      {
        path: '/academic-calendar',
        name: 'academic-calendar',
        component: () => import('@/views/AcademicCalendarView.vue')
      },
      {
        path: '/news-detail/:slug',
        name: 'news-detail',
        component: () => import('@/views/NewsDetailView.vue')
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
